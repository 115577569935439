import styled, { css } from "styled-components";

const BodyContainer = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 1rem;
    width: 100%;

    & .SidebarOpen {
        transform: translateX(0);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const BeforeMain = styled.main`
    display: flex;
    flex-direction: column;
    flex: 6 0 0;
    overflow-y: auto;
    overflow-x: hidden;
`;

type MainProps = {
    padding: boolean;
};

const SubTitle = styled.h2`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--text-normal);
`;

const Main = styled.main<MainProps>`
      display: flex;
      flex-direction: column;
      flex: 6 0 0;
      gap: 1rem;
  
      ${({ padding }) => {
        if (padding) {
            return css`
                    padding-bottom: 80px;
                `;
        }
    }}
    `;
const CenteredInfo = styled.div`
    display: flex;
    align-items: center;
    min-height: 100px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 0 2rem;
    z-index: 5;

    @media (max-width: 768px) {
      padding: 0 1rem;
    }
`;

const sectionCSS = css`
    border-radius: 10px;
    padding: 1rem;
    background: var(--wrapper-bg);
    border: var(--wrapper-border);
    box-shadow: var(--wrapper-shadow);
    display: grid;
    @media (max-width: 768px) {
      padding: 0.5rem;
    }
`;

const Trending = styled.div`
    grid-area: trending;
    ${sectionCSS}
`;

const Best = styled.div`
    grid-area: best;
    margin: 1.2rem 0;
    ${sectionCSS}
`;

const Top = styled.div`
    grid-area: top;
    //min-height: 200px;
    ${sectionCSS}
`;

const EmptyWall = styled.div`
  height: calc(100vh - 160px);
  text-align: center;
  color: var(--text-normal);
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 760px) {
    height: calc(100vh - 180px);
  }
`;

export {
    BodyContainer,
    BeforeMain,
    Main,
    SubTitle,
    CenteredInfo,
    Trending,
    Best,
    Top,
    EmptyWall
};