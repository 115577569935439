import { usePlaywallContext } from '../contexts';
import { Button, Total, Amount } from '../styles/EarningsBtnStyles';
import { useNavigate, useLocation } from 'react-router-dom';

export default function EarningBtn() {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        earnings
    } = usePlaywallContext();


    return <Button onClick={() => { navigate(`/earnings/${location.search}`); }}>
        <Total>TOTAL <br /> <span>EARNINGS</span></Total>
        <Amount>{Intl.NumberFormat('en-US', {
            style: 'currency',
            // currency: his.currency
            currency: 'USD',
            maximumFractionDigits: 2
        }).format(earnings?.totalEarnings || 0)}</Amount>
    </Button>;
}
