import styled from 'styled-components';

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Image = styled.img`
  max-height: 450px;
  max-width: 100%;
  height: auto;
`;

const Title = styled.h1`
  font-size: 3rem;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgb(65 72 93 / var(--tw-text-opacity));
  font-weight: 900;
  text-align: center;
`;

const Desc = styled.p`
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(65 72 93 / var(--tw-text-opacity));
  font-weight: 700;
  text-align: center;
`;

const Button = styled.button`
  background-image: linear-gradient(290deg, #B13030 30.17%, #DE5947 97.55%);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border: none;
  height: 50px;
  width: 100px;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: 500;
  border-radius: 5px !important;
  cursor: pointer;
`;

export {
  Container,
  Image,
  Title,
  Desc,
  Button
};