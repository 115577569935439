
import { usePlaywallContext } from "./contexts";
import { BodyContainer } from "./styles/HomePageStyles";
import AppHeader from "./components/AppHeader";
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import ErrorPage from "./pages/ErrorPage";
import { earnings_route, ROUTE_CONFIG } from "./route-config";
import SideBar from "./components/SideBar";
import styled from "styled-components";
import EarningBtn from "./components/EarningBtn";
import { CloseIconWrapper, Search, SearchBox } from "./styles/AppHeaderStyles";
import { CloseIcon, LeftIcon, SearchIcon } from "./components/icons";
import { Earnings } from "./types";
import React from "react";

const StickySearchStyle = styled.div<{ top: string; }>`
  position: sticky;
  background: var(--full-bg);
  top: ${props => props.top};
  z-index: 2;
`;

export const DesktopView = styled.div`
    display: flex;
  @media screen and (max-width: 768px) {
        display: none;
    }    
`;

export const MobileView = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
        display: flex;
    }
`;

export const Spacer = styled.div<{ height: string; }>`
  height: ${props => props.height};
`;

const BackActionStyle = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const BackActionText = styled.p`
    font-size: 12px;
    font-weight: 500;
    color: var(--action-back-text-color);
`;

const BackActionIcon = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: var(--action-back-icon-bg);
    padding: 2px;
`;

const BackAction = () => {
    return (
        <BackActionStyle onClick={() => window.history.back()}>
            <BackActionIcon>
                <LeftIcon fill={'var(--action-back-icon-color)'} />
            </BackActionIcon>
            <BackActionText>back</BackActionText>
        </BackActionStyle>
    );
};

const StickySearch = () => {
    const location = useLocation();

    const {
        searchValue,
        setSearchValue,
        getUUID,
        setEarnings
    } = usePlaywallContext();

    const uuid = getUUID();

    const fetchEarnings = async (
        uuid: string
    ) => {
        try {
            const res = await fetch(`https://offers-api.santabrowser.com/${uuid}/stats/summary`);
            const data: Earnings = await res.json();

            setEarnings(data);
        } catch (error: any) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        if (!uuid) return;
        fetchEarnings(uuid);
    }, []);
    return location.pathname.startsWith(earnings_route) ? (
        <StickySearchStyle top={'0px'}>
            <MobileView style={{ flexDirection: 'column' }}>
                <BackAction />
            </MobileView>
        </StickySearchStyle>
    ) : (
        <StickySearchStyle top={'-41px'}>
            <MobileView style={{ flexDirection: 'column' }}>
                <EarningBtn />
                <Spacer height={'10px'} />
                <SearchBox strict clearable={searchValue.length > 0}>
                    <SearchIcon fill={'var(--search-icon)'} />
                    <Search placeholder="Search" value={searchValue} onChange={(e) => {
                        setSearchValue(e.target?.value?.replace(/^\s+/, ''));
                    }} />
                    <CloseIconWrapper onClick={() => { setSearchValue(''); }}>
                        <CloseIcon fill={'var(--search-icon)'} />
                    </CloseIconWrapper>
                </SearchBox>
                <Spacer height={'10px'} />
            </MobileView>
        </StickySearchStyle>
    );
};

const Main = styled.main`
    padding-left: 1rem;
    padding-right: 1rem;
`;


const AppView = styled.div`
height: 100vh;
overflow-y: none;

@media screen and (max-width: 768px) {
    overflow-y: auto;
    }

&::-webkit-scrollbar-track {
    margin-top: 60px;
}
`;

export default function App() {
    const {
        getUUID
    } = usePlaywallContext();

    const uuid = getUUID();

    return <>
        {uuid ? (
            <BrowserRouter>
                <Main>
                    <AppView id='scrollableDiv'>
                        <AppHeader />

                        <StickySearch />

                        <BodyContainer>
                            <SideBar />

                            <Routes>
                                {
                                    ROUTE_CONFIG.map((item, index) => {
                                        return <Route key={index} path={item.path} element={<item.Element />} />;
                                    })
                                }
                            </Routes>
                        </BodyContainer>
                    </AppView>
                </Main>
            </BrowserRouter>
        ) : <ErrorPage errorMessage={'Invalid UUID'} />};
    </>;
}