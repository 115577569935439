import { RecoilRoot } from 'recoil';

import { PlaywallProvider } from '../contexts';
import App from '../App';

export const RouterRoot = () => {
    return (
        <RecoilRoot>
            <PlaywallProvider children={<App />} />
        </RecoilRoot>
    );
};
