/* eslint-disable @typescript-eslint/no-explicit-any */

import HomePage from './pages/HomePage';
import EarningsPage from './pages/EarningsPage';

export const home_route = '/';
export const earnings_route = '/earnings';

export const ROUTE_CONFIG = [
  {
    path: home_route,
    name: 'OfferWallPage',
    Element: (...rest: any) => <HomePage {...rest} />,
  },
  {
    path: earnings_route,
    name: 'EarningsPage',
    Element: (...rest: any) => <EarningsPage  {...rest} />,
  }
];
