import React, { createContext, useContext, useState, ReactNode } from 'react';
import { AllIcon, AppIcon, DepositeIcon, GameIcon, OthersIcon, PurchaseIcon, QuizIcon, SignUpIcon, SurveyIcon } from '../components/icons';
import { Earnings, Platform, SortBy, Tab } from '../types';
import { defaultTab } from '../utils';

export const platforms: Platform[] = [
    { name: 'All', id: 'all' },
    { name: 'Android', id: 'and' },
    { name: 'IOS', id: 'ios' },
    { name: 'Windows', id: 'win' }
];

export const sortbys: SortBy[] = [
    { id: 'popular', name: 'Popular' },
    { id: 'high-reward', name: 'Highest Reward' },
    { id: 'low-reward', name: 'Lowest Reward' }
];

export const tabs: Tab[] = [
    { name: 'All', icon(fill: string) { return <AllIcon fill={fill} />; } },
    { name: 'Sign Up', icon(fill: string) { return <SignUpIcon fill={fill} />; } },
    { name: 'Game', icon(fill: string) { return <GameIcon fill={fill} />; } },
    { name: 'App', icon(fill: string) { return <AppIcon fill={fill} />; } },
    { name: 'Deposit', icon(fill: string) { return <DepositeIcon fill={fill} />; } },
    { name: 'Purchase', icon(fill: string) { return <PurchaseIcon fill={fill} />; } },
    { name: 'Quiz', icon(fill: string) { return <QuizIcon fill={fill} />; } },
    { name: 'Survey', icon(fill: string) { return <SurveyIcon fill={fill} />; } },
    { name: 'Others', icon(fill: string) { return <OthersIcon fill={fill} />; } },
];

interface PlaywallContextType {
    getUUID: () => string | null;
    activeTab: Tab;
    toggleActiveTab: (tabIndex: number) => void;
    searchValue: string;
    setSearchValue: (searchValue: string) => void;
    isSidebarOpen: boolean;
    setIsSidebarOpen: (isSidebarOpen: boolean) => void;
    activePlatform: Platform;
    toggleActivePlatform: (platformIndex: number) => void;
    activeSortBy: SortBy;
    toggleActiveSortBy: (sortbyIndex: number) => void;
    earnings: Earnings | null;
    setEarnings: (earnings: Earnings | null) => void;
}

const PlaywallContext = createContext<PlaywallContextType | undefined>(undefined);

interface PlaywallContextProviderProps {
    children: ReactNode;
}

export const PlaywallProvider: React.FC<PlaywallContextProviderProps> = ({ children }) => {
    const [activeTab, setActiveTab] = useState<Tab>(defaultTab());
    const [searchValue, setSearchValue] = useState<string>('');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activePlatform, setActivePlatform] = useState<Platform>(platforms[0]);
    const [activeSortBy, setActiveSortBy] = useState<SortBy>(sortbys[0]);
    const [earnings, setEarnings] = React.useState<Earnings | null>(null);

    const toggleActiveTab = (tabIndex: number) => {
        setActiveTab(tabs[tabIndex]);
    };

    const toggleActivePlatform = (platformIndex: number) => {
        setActivePlatform(platforms[platformIndex]);
    };

    const toggleActiveSortBy = (sortbyIndex: number) => {
        setActiveSortBy(sortbys[sortbyIndex]);
    };

    const getUUID = (): string | null => {
        return (new URL(window.location.href).searchParams.get('uuid'));
    };

    return (
        <PlaywallContext.Provider value={{
            getUUID,
            activeTab,
            toggleActiveTab,
            searchValue,
            setSearchValue,
            activePlatform,
            toggleActivePlatform,
            activeSortBy,
            toggleActiveSortBy,
            isSidebarOpen,
            setIsSidebarOpen,
            earnings,
            setEarnings
        }
        }>
            {children}
        </PlaywallContext.Provider>
    );
};

export const usePlaywallContext = () => {
    const context = useContext(PlaywallContext);
    if (!context) {
        throw new Error('usePlaywallContext must be used within a PlaywallProvider');
    }
    return context;
};
