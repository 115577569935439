/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from "react";
import { History, HistoryData, Tabs } from "../types";
import { usePlaywallContext } from "../contexts";
import { BeforeMain, Main } from "../styles/HomePageStyles";
import SpinnerLoader from "../components/SpinnerLoader";

const EarningsPage = React.forwardRef(() => {
    const {
        getUUID,
        earnings
    } = usePlaywallContext();

    const [loadingHistory, setLoadingHistory] = useState(false);
    const [loadingHistoryPaging, setLoadingHistoryPaging] = useState(false);

    const [activeTab, setActiveTab] = React.useState<Tabs>('all');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [total, setTotal] = useState(0);
    const [totalInProgress, setTotalInProgress] = useState(0);
    const [history, setHistory] = useState<History[]>([]);
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);

    const uuid = getUUID();

    const getHistory = async (
        uuid: string, tab: Tabs, page: number
    ): Promise<{ total: number, lastPage: number, history: History[]; }> => {
        try {
            const res = await fetch(`https://offers-api.santabrowser.com/${uuid}/stats/history?pageSize=10&pageNo=${page}&status=${tab === 'all' ? '' : tab}`);
            const data: HistoryData = await res.json();

            return { total: data.totalItems, lastPage: data.totalPages, history: data.data };
        } catch (error: any) {
            // renderReact({ fail: true, fail_message: error.message });
            return { total: 0, lastPage: 0, history: [] };
        }
    };

    const getTotalProgress = async (
        uuid: string
    ) => {
        try {
            const res = await fetch(`https://offers-api.santabrowser.com/${uuid}/stats/history?pageSize=1&pageNo=${0}&status=${'In Progress'}`);
            const data: HistoryData = await res.json();
            setTotalInProgress(data.totalItems);
        } catch (error: any) {
            console.log(error);
        }
    };

    const fetchHistory = async (tab: Tabs) => {
        if (!uuid) return;
        setLoadingHistory(true);
        const result = await getHistory(uuid, tab, 0);
        setHistory(result.history);
        setLastPage(result.lastPage);
        setTotal(result.lastPage);
        setPage(0);
        setLoadingHistory(false);
    };

    React.useEffect(() => {
        if (!uuid) return;
        getTotalProgress(uuid);
    }, [uuid]);

    React.useEffect(() => {
        setHistory([]);
        fetchHistory(activeTab);
    }, [activeTab]);

    const handleScroll = async (event: any) => {
        if (!uuid) return;

        if (loadingHistory || loadingHistoryPaging || page >= (lastPage - 1)) {
            return;
        }

        const scrollRemaining =
            event.currentTarget.scrollHeight -
            event.currentTarget.scrollTop -
            event.currentTarget.clientHeight;

        if (scrollRemaining < 1) {
            setLoadingHistoryPaging(true);
            const result = await getHistory(uuid, activeTab, page + 1);
            setHistory([...history, ...result.history]);
            setLastPage(result.lastPage);
            setTotal(result.lastPage);
            setPage(page + 1);
            setLoadingHistoryPaging(false);
        }
    };

    return (
        <BeforeMain>
            <div className="earnings_container h-[calc(100%)] w-full">
                {loadingHistory && (
                    <div className='loader'>
                        <SpinnerLoader plain />
                    </div>
                )}

                {loadingHistoryPaging && (
                    <div className='pager'>
                        <SpinnerLoader plain />
                    </div>
                )}

                <div className="earnings_wrapper">
                    <div className="earnings_stats">
                        <div className="earnings_stat">
                            <p className="earnings_stat_name">My Earnings</p>
                            <h3 className="earnings_stat_value">
                                {Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 2
                                }).format(earnings?.totalEarnings || 0)}
                            </h3>
                            <p className="earnings_stat_date">Past 3 Months</p>
                        </div>

                        <div className="earnings_stat">
                            <p className="earnings_stat_name">In Progress</p>
                            <h3 className="earnings_stat_value">
                                {totalInProgress}
                            </h3>
                            <p className="earnings_stat_date">Tasks</p>
                        </div>
                    </div>

                    <div className="history">
                        <h3 className="history_title">History</h3>
                        <div className="history_tabs">
                            <button className={
                                activeTab === 'all' ? 'history_tab history_tab_active' : 'history_tab'
                            } onClick={() => setActiveTab('all')}>All</button>
                            <button className={
                                activeTab === 'Completed' ? 'history_tab history_tab_active' : 'history_tab'
                            } onClick={() => setActiveTab('Completed')}>Completed</button>
                            <button className={
                                activeTab === 'In Progress' ? 'history_tab history_tab_active' : 'history_tab'
                            } onClick={() => setActiveTab('In Progress')}>In Progress</button>
                        </div>
                    </div>

                    <div className="history_table_wrapper" onScroll={handleScroll}>
                        {activeTab === 'all' && (
                            <>{historyTable(history)}</>
                        )}
                        {activeTab === 'all' && history.length === 0 && !loadingHistory && (
                            <>{empty('No history found.')}</>
                        )}

                        {activeTab === 'Completed' && (
                            <>{historyTable(history)}</>
                        )}
                        {activeTab === 'Completed' && history.length === 0 && !loadingHistory && (
                            <>{empty('No completed history found.')}</>
                        )}

                        {activeTab === 'In Progress' && (
                            <>{historyTable(history)}</>
                        )}

                        {activeTab === 'In Progress' && history.length === 0 && !loadingHistory && (
                            <>{empty('No pending history found.')}</>
                        )}
                    </div>
                </div>
            </div>
        </BeforeMain>
    );
});

EarningsPage.displayName = 'EarningsPage';
export default EarningsPage;


function historyTable(history: History[]) {
    return (
        <table className='history_table'>
            <thead className='history_table_head'>
                <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Date</th>
                </tr>
            </thead>
            {history.map((his, index) => (
                <tbody className='history_table_body' key={index}>
                    <tr>
                        <td>
                            <div className="table_name">
                                <img src={his.imageUrl} alt="" />
                                <div className="table_name_text">
                                    <p>{his.name}</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="table_text">
                                {Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                }).format(his.revenue)}
                            </div>
                        </td>
                        <td>
                            <div className={`table_text ${his.status === 'In Progress' ? 'table_text_pending' : ''}  ${his.status === 'Completed' ? 'table_text_completed' : ''}`}>
                                {his.status}
                            </div>
                        </td>
                        <td>
                            <div className="table_text">
                                {toReadableDate(his.date)}
                            </div>
                        </td>
                    </tr>
                </tbody>
            ))}
        </table>
    );
}

function empty(message: string) {
    return <div>
        <div className="empty_table_msg">
            <p>{message}</p>
        </div>
    </div>;
}

function toReadableDate(dateString: string): string {
    const [day, month, year] = dateString.split("/").map(Number);
    const dateObject = new Date(year, month - 1, day); // month is 0-based
    return Intl.DateTimeFormat('en', {
        dateStyle: 'medium',
    }).format(dateObject);
}