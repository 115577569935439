import {
    SideBarOutside,
    Category,
    CategoryName,
    SideBar as SideBarStyle
} from "../styles/AppHeaderStyles";
import { tabs, usePlaywallContext } from "../contexts";
import { useNavigate, useLocation } from 'react-router-dom';
import styled from "styled-components";

export const SideNavStyle = styled.div`
background: var(--sidebar-bg);
height: calc(100vh - 70px);
position: sticky;
top: 60px;

@media screen and (max-width: 768px) {
  position: absolute;
  top: 0;
  height: 100vh;
}
`;


export default function SideBar() {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        activeTab,
        toggleActiveTab,
        isSidebarOpen,
        setIsSidebarOpen,
    } = usePlaywallContext();

    return (
        <SideNavStyle>
            <SideBarStyle className={`${isSidebarOpen ? 'SidebarOpen' : ''}`} active={isSidebarOpen}>
                {tabs.map((tab, index) => {
                    return (
                        <Category
                            key={index}
                            active={(tab.name === activeTab.name)}
                            onClick={() => {
                                navigate(`/${location.search}`);
                                toggleActiveTab(index);
                                setIsSidebarOpen(false);
                            }}
                        >
                            {tab.icon((tab.name === activeTab.name) ? 'var(--sidebar-item-active-color)' : 'var(--sidebar-item-color)')}
                            <CategoryName>{tab.name}</CategoryName>
                        </Category>
                    );
                })}

                <SideBarOutside active={isSidebarOpen} onClick={() => setIsSidebarOpen(false)} />
            </SideBarStyle>
        </SideNavStyle>
    );
}