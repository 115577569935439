import styled, { css } from "styled-components";

type OfferContainerProps = {
  flat?: boolean;
  singleRow?: boolean;
  width?: string;
};

const OfferContainer = styled.div<OfferContainerProps>`
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(${({ width }) => `${width}`}, 1fr));
      grid-gap: 20px;
      border-radius: 0.625rem;
      padding-top: 0.5rem;
  
      @media (max-width: 768px) {
        grid-gap: 0.5rem;
      }
  
      ${({ singleRow, flat }) => {
    if (flat) {
      return css`
                @media (max-width: 768px) {
                    display: grid;
                    grid-gap: 0.5rem;
                    border-radius: 0.625rem;
                    row-gap: 0;
                    grid-template-rows: auto;
                    grid-auto-rows: 0;
                    overflow: hidden;
                    overflow-X: scroll;
                    grid-template-columns: repeat(10, 125px);
                }
            `;
    }

    if (singleRow) {
      return css`
                row-gap: 0;
                grid-template-rows: auto;
                grid-auto-rows: 0;
                overflow: hidden;
            `;
    }
  }

  }
  `;

const OfferWrapper = styled.div`
      border-radius: 6px;
      background: var(--offers-border));
      padding: 1px;
      overflow: hidden;
      cursor: pointer;
  
      &:hover {
        background: var(--offers-hover-border);
      }
  `;

const Offer = styled.div<{ mini: boolean; }>`
      border-radius: 6px;
      display: grid;
      grid-template-rows: 1fr auto auto auto;
      background: var(--offers-bg);
      padding: ${props => props.mini ? '0.5rem' : '0 0 0.5rem 0'};
      gap: 0.5rem;
      position: relative;
      text-align: center;
      overflow: hidden;
  
      &:hover {
        background: var(--offers-hover-bg);
      }
  
      &:hover > div > img {
        transition: transform 0.2s ease;
        transform: scale(1.1); 
      }
  `;

const OfferCategory = styled.span<{ mini: boolean; }>`
    position: absolute;
    left: ${props => props.mini ? 'unset' : '0.4rem'};
    top: ${props => props.mini ? '4.8rem' : '0.4rem'};
    right: ${props => props.mini ? '0.8rem' : 'unset'};
    z-index: 1;
    align-self: center;
    background: var(--offers-tag-bg);
    box-shadow: var(--offers-tag-shadow);
    border-radius: 3px;
    color: var(--offers-tag-color);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    padding: 2px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    align-self: center;
    width: fit-content;
`;

const OfferDevice = styled.div<{ mini: boolean; }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.mini ? '4px' : '8px'};
    border-radius: 6px;
    padding: ${props => props.mini ? '4px 3px' : '6px 5px'};
    background: var(--offers-platforms-bg);
    justify-content: center;
    position: absolute;
    top: ${props => props.mini ? '0.5rem' : '0'};
    right: ${props => props.mini ? '0.5rem' : '0'};
    z-index: 1;
`;

const OfferDeviceImage = styled.svg`
  width: 20px;
  fill: #fff;
`;


const OfferTileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const OfferImageWrapper = styled.div`
  overflow: hidden;
  height: 93px;
  border-radius: 0.3125rem;
`;

const OfferTitle = styled.h2<{ mini: boolean; }>`
    color: var(--text-dimmed);
    font-size: 0.7rem;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: ${props => props.mini ? '0' : '0 0.4rem'};
`;

const OfferContent = styled.span``;

const OfferPrice = styled.div`
    color: var(--offers-price);
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
`;

export {
  OfferContainer,
  OfferWrapper,
  Offer,
  OfferCategory,
  OfferDevice,
  OfferDeviceImage,
  OfferTileImage,
  OfferImageWrapper,
  OfferTitle,
  OfferContent,
  OfferPrice
};